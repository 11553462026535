// Import FirebaseAuth and getFirebase().
import React, {useEffect, useState} from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import {navigate} from "gatsby";
import * as queryString from "query-string";

import Signedlayout from "../components/Signedlayout";
import SelectProductCard from "../components/SelectProductCard";
import Product from "../components/Product";
import {getStringPriceFromCents, skeletonProducts} from "./my-products";
import {reduce} from "ramda";
import CreateProjectModal from "../components/CreateProjectModal";
import DeleteProjectModal from "../components/DeleteProjectModal";
import UpdateProjectModal from "../components/UpdateProjectModal";
import {getUserLimits, getUserOffer} from "./account";
import {getFirebase} from "../utils/signin";

export const handleUpdateShoppingList = async (projectId, shoppingListId, userId) => {
    if (!projectId || !shoppingListId) return;

    const db = getFirebase().firestore();
    const productsRef = db.collection('products');
    const productsQuerySnapshot = await productsRef
        .where("authorId", "==", userId)
        .where("projectsId", "array-contains", projectId)
        .get();
    const products = reduce((acc, curr) => {
        const product = curr.data();
        const {
            title,
            price,
            url,
            images,
            tags,
            unitsByProjectId,
        } = product;
        const units = unitsByProjectId && unitsByProjectId[projectId];
        const data = {
            id: curr.id,
            title,
            price: getStringPriceFromCents(price),
            priceInCents: price,
            tags,
            url,
            image: images && images[0],
            units: units ? units : 1,
        }
        return ({...acc, [data.id]: data});
    }, {}, productsQuerySnapshot.docs);
    console.log('handleUpdateShoppingList', shoppingListId, products)
    await db.collection(`shopping-lists`).doc(shoppingListId)
        .set({
            products,
        }, {merge: false});

}

function MyProject({location}) {
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [userId, setUserId] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [myProject, setMyProject] = useState(null);
    const [products, setProducts] = useState([]);
    const [totalProductsPrice, setTotalProductsPrice] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [showCopiedUrl, setShowCopiedUrl] = useState(false);
    const [isLoadingMutation, setIsLoadingMutation] = useState(false);
    const [isOpenDeleteProjectModal, setIsOpenDeleteProjectModal] = useState(false);
    const [isOpenUpdateProjectModal, setIsOpenUpdateProjectModal] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showPremiumError, setShowPremiumError] = useState(false);
    const [userLimits, setUserLimits] = useState({});
    useEffect(() => {
        const unregisterAuthObserver = getFirebase().auth().onAuthStateChanged(async user => {
            setIsSignedIn(!!user);
            setUserId(user.uid);
            setUserEmail(user.email);
            setUserLimits(await getUserLimits(user.uid));
            const {projectId} = queryString.parse(location.search);
            const db = getFirebase().firestore();
            const projectsRef = db.collection('projects');
            projectsRef
                .doc(projectId)
                .get().then((doc) => {
                console.log('querySnapshot', user.uid, projectId, doc.exists, doc.data())
                if (!doc.exists) {
                    return;//error;
                }

                const {
                    title,
                    description,
                    budget,
                    shoppingListId,
                } = doc.data();
                setMyProject({
                    id: projectId,
                    title,
                    description,
                    budget,
                    shoppingListId,
                });
                const productsRef = db.collection('products');
                productsRef
                    .where("authorId", "==", user.uid)
                    .where("projectsId", "array-contains", projectId)
                    .get().then((querySnapshot) => {
                    const products = querySnapshot.docs.map((doc) => {
                        console.log(doc.id, " => ", doc.data());
                        const product = doc.data();
                        const {
                            title,
                            reference,
                            price,
                            url,
                            images,
                            tags,
                            dimension,
                            myDescription,
                            unitsByProjectId,
                        } = product;
                        const units = unitsByProjectId && unitsByProjectId[projectId];
                        return {
                            id: doc.id,
                            title,
                            price: getStringPriceFromCents(price),
                            priceInCents: price,
                            tags,
                            url,
                            image: images && images[0],
                            units: units ? units : 1,
                        }
                    });
                    setProducts(products);
                    setTotalProductsPrice(reduce((acc, {priceInCents, units}) => acc + priceInCents * units, 0, products));
                }).catch(() => {
                    handleShowError();
                });

                setIsLoading(false);
            }).catch(() => {
                handleShowError();
            });
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);
    const {
        id,
        title,
        description,
        budget,
        shoppingListId,
    } = myProject || {};
    const shoppingListUrl = `https://deerhome.fr/shopping-list?shoppingListId=${shoppingListId}`;
    const budgetRespect = totalProductsPrice / budget;
    const budgetDiff = Math.abs(budget - (totalProductsPrice / 100));
    console.log('products', products)
    const handleAddProducts = () => {
        const withShoppingListId = shoppingListId ? `&shoppingListId=${shoppingListId}` : '';
        const url = `/my-products?projectId=${id}${withShoppingListId}`;
        navigate(url);
    }
    const handleShowError = () => {
        setShowError(true);
        setIsLoadingMutation(false);
        setTimeout(() => {
            setShowError(false);
        }, 3000);
    }
    const handleGenerateShoppingList = (event) => {
        event.preventDefault();
        if (!userId || !isSignedIn) return 'SHOW ERROR';
        if (!userLimits.shoppingListEnabled) {
            handleShowPremiumError();
            return;
        }
        setIsLoadingMutation(true);
        const newShoppingList = {
            authorId: userId,
            authorEmail: userEmail,
            projectId: id,
            title,
            products: reduce((acc, curr) => ({...acc, [curr.id]: curr}), {}, products),
        }
        const firestoreDb = getFirebase().firestore();
        firestoreDb.collection(`shopping-lists`).add(newShoppingList)
            .then((docRef) => {
                const projectsRef = firestoreDb.collection('projects');
                projectsRef.doc(id).set({
                    shoppingListId: docRef.id
                }, {merge: true}).then(() => {
                    setMyProject({
                        ...myProject,
                        shoppingListId: docRef.id
                    });
                    setIsLoadingMutation(false);
                }).catch(() => {
                    handleShowError();
                });
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
                handleShowError();
            });
    }
    const handleShowPremiumError = () => {
        setShowPremiumError(true);
        setIsLoadingMutation(false);
        setTimeout(() => {
            setShowPremiumError(false);
        }, 50000);
    }
    const handleRemoveProject = async (event) => {
        event.preventDefault();
        try {
            setIsLoadingMutation(true);
            const db = getFirebase().firestore();
            const projectsRef = db.collection('projects');
            await projectsRef
                .doc(id)
                .delete();
            setIsLoadingMutation(false);
            setIsOpenDeleteProjectModal(false);
            navigate('/my-projects');
        } catch (e) {
            handleShowError();
        }
    }
    const handleOpenCautionRemoveProject = async (event) => {
        event.preventDefault();
        setIsOpenDeleteProjectModal(true);
    }
    const handleUpdateProject = async (project) => {
        try {
            setIsLoadingMutation(true);
            const db = getFirebase().firestore();
            const projectsRef = db.collection('projects');
            await projectsRef.doc(id).set({
                ...project,
            }, {merge: true});
            window.location.reload();
            setIsLoadingMutation(false);
            setIsOpenUpdateProjectModal(false);

        } catch (e) {
            handleShowError();
        }
    }
    const handleOpenUpdateProjectModal = async (event) => {
        event.preventDefault();
        setIsOpenUpdateProjectModal(true);
    }
    const handleCopyShoppingListUrl = (e, handler = Function.prototype) => {
        setShowCopiedUrl(true);
        navigator.clipboard.writeText(shoppingListUrl).then(() => {
            handler();
        });
        setTimeout(() => {
            setShowCopiedUrl(false);
        }, 1000);
    }
    const handleOpenShoppingList = () => {
        handleCopyShoppingListUrl(null, () => window.open(shoppingListUrl));
    }

    return (
        <Signedlayout>
            {isLoadingMutation && (
                <div
                    className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50 flex items-center justify-center">
                    <svg className="animate-spin ml-1 mr-3 h-28 w-28 text-blue-700"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                stroke-width="4"></circle>
                        <path className="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            )}
            <div className="pt-24 pb-24">
                <div className="container px-3 mx-auto">
                    {isLoading ? (
                        <div className=" p-4 max-w-2xl w-full mx-auto">
                            <div className="animate-pulse flex space-x-40">
                                <div className="gradient h-96 w-80"></div>
                                <div className="flex-1 space-y-4 py-1">
                                    <div className="h-4 gradient rounded w-60"></div>
                                    <div className="space-y-2">
                                        <div className="h-5 gradient rounded"></div>
                                        <div className="h-5 gradient rounded w-5/6"></div>
                                    </div>
                                    <div className="h-4 gradient rounded w-60"></div>
                                    <div className="space-y-2">
                                        <div className="h-4 gradient rounded"></div>
                                        <div className="h-4 gradient rounded w-5/6"></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    ) : (<div className="w-full mx-auto">
                            <div className='flex justify-between'>
                                <h3 className="text-gray-700 uppercase text-lg">{title}</h3>
                                <div>
                                    <button onClick={handleOpenUpdateProjectModal}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-700"
                                             fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"/>
                                        </svg>
                                    </button>
                                    <button onClick={handleOpenCautionRemoveProject}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-400 ml-4"
                                             fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <hr className="my-3"/>
                            <div className="mt-2">
                                <label className="text-blue-900 text-sm" htmlFor="count">Description
                                    :</label>
                                <div className="flex items-center mt-1">
                                    <span className="text-gray-700 ">{description}</span>
                                </div>
                            </div>
                            <div className="mt-2">
                                <label className="text-blue-900 text-sm"
                                       htmlFor="count">Budget du client :</label>
                                <div className="flex items-center mt-1">
                                    <span className="text-gray-700 ">{budget} €</span>
                                </div>
                            </div>
                            <div className="mt-2">
                                <label className="text-blue-900 text-sm"
                                       htmlFor="count">Total des produits :</label>
                                <div className="flex items-center mt-1">
                                    <span
                                        className="text-gray-700 ">{getStringPriceFromCents(totalProductsPrice)} €</span>
                                </div>
                            </div>
                            <div className="mt-2">
                                <label className="text-blue-900 text-sm"
                                       htmlFor="count">Respect du budget :</label>
                                <div className="flex items-center mt-1">
                                    <span className={budgetRespect > 100 ? 'text-red-700' : 'text-green-700'}>
                                        {budgetRespect.toFixed(0)}% du budget | {budgetRespect > 100 ? '+' : '-'}{budgetDiff.toFixed(0)} €
                                    </span>
                                </div>
                            </div>
                            <div className="mt-2">
                                <label className="text-blue-900 text-sm flex align-middle" htmlFor="count">
                                    Liste shopping URL:
                                    {shoppingListId && (
                                        <button onClick={handleCopyShoppingListUrl}>
                                            <svg className="h-5 w-5 text-blue-500 ml-1" fill="none"
                                                 viewBox="0 0 24 24"
                                                 stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                      stroke-width="2"
                                                      d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"/>
                                            </svg>
                                        </button>
                                    )}
                                </label>
                                {showCopiedUrl && (
                                    <div className="mt-2">
                                                <span
                                                    className="text-blue-700 text-sm bg-blue-50 p-2 rounded">URL copiée !</span>
                                    </div>
                                )}
                                <div
                                    className="flex items-center bg-blue-400 text-white rounded text-sm px-4 py-3 w-2/6 my-4"
                                    role="alert">
                                    <svg className="fill-current w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 20 20">
                                        <path
                                            d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/>
                                    </svg>
                                    <p>Partagez l'url ci-dessous à votre client pour lui envoyer votre liste shopping et
                                        obtenir des retours.</p>
                                </div>
                                <div className="flex items-center mt-1">
                                    {shoppingListId ? (
                                        <button className="text-gray-500 text-xs hover:underline cursor-pointer"
                                                onClick={handleOpenShoppingList}>{shoppingListUrl}</button>
                                    ) : (
                                        <button
                                            className="gradient text-white transform hover:scale-105 py-2 px-4 rounded"
                                            onClick={handleGenerateShoppingList}
                                        >
                                            Générer la liste shopping
                                        </button>
                                    )}
                                </div>
                            </div>

                            <div className="mt-6 flex justify-center">
                                <button
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-500 rounded"
                                    onClick={handleAddProducts}
                                >
                                    {products.length > 0 ? 'Ajouter ou supprimer des produits' : 'Ajouter des produits'}
                                </button>
                            </div>
                            <div className="mt-2">
                                <label className="text-blue-900 text-sm" htmlFor="count">Produits
                                    :</label>
                                <div className='flex'>
                                    <span
                                        className="mt-3 text-sm text-gray-500">{products.length} Produits</span>
                                </div>
                            </div>
                            <div
                                className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6 pb-6">
                                {isLoading && (
                                    skeletonProducts.map(() => (
                                        <div className=" p-4 max-w-2xl w-full mx-auto">
                                            <div className="animate-pulse flex space-x-40">
                                                <div className="gradient h-72 w-full"></div>
                                            </div>
                                        </div>
                                    ))
                                )}
                                {products.map(({title, price, image, id, units}) => (
                                    <Product title={title} price={price} image={image} id={id} units={units}/>
                                ))}

                            </div>
                        </div>
                    )}
                </div>
            </div>
            <UpdateProjectModal project={myProject} handleUpdateProject={handleUpdateProject} isOpen={isOpenUpdateProjectModal} setIsOpen={setIsOpenUpdateProjectModal}/>
            <DeleteProjectModal handleRemoveProject={handleRemoveProject} isOpen={isOpenDeleteProjectModal} setIsOpen={setIsOpenDeleteProjectModal} />
            {showError && (
                <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded fixed bottom-6 w-2/6 left-0 right-0 ml-auto mr-auto"
                    role="alert">
                    <strong className="font-bold">Erreur! </strong>
                    <span className="block sm:inline">Une erreur est survenue, veuillez réessayer plus tard.</span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg className="fill-current h-6 w-6 text-red-500" role="button"
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 20 20"><title>Close</title><path
                            d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                      </span>
                </div>
            )}
            {showPremiumError && (
                <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded fixed top-40 w-2/6 left-0 right-0 ml-auto mr-auto"
                    role="alert">
                    <div className='mr-10'>
                        <strong className="font-bold">Erreur, </strong>
                        <span className="block sm:inline">Vous devez souscrire à une offre supérieure pour accéder à plus de capacité.</span>
                    </div>
                    <div className='text-center'>
                        <button className="bg-blue-500 text-white rounded p-2 ml-4 mt-2 transform hover:scale-110"
                                onClick={() => navigate('/#pricing')}>Changer d'offre 🚀</button>
                    </div>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg className="fill-current h-6 w-6 text-red-500" role="button"
                             onClick={() => setShowPremiumError(false)}
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 20 20"><title>Close</title><path
                            d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                      </span>
                </div>
            )}
        </Signedlayout>
    );
}

export default MyProject;