import React, {Fragment, useEffect, useRef, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import Modal from "../Modal";
import firebase from "firebase";
import Layout from "../layout";
import SEO from "../seo";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import {getUiConfig, uiConfig} from "../../utils/signin";

const UpdateProjectModal = ({handleUpdateProject, isOpen = false, setIsOpen, project = {}}) => {
    const handleEditProject = async (event) => {
        event.preventDefault();
        const projectEdited = {
            title: event.target.title.value,
            budget: parseFloat(event.target.budget.value),
            description: event.target.description.value,
        }
        await handleUpdateProject(projectEdited);
    }
    if (!project) return null;

    const {title = '', budget = 0, description = ''} = project;

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <div
                className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="relative  sm:max-w-xl sm:mx-auto">
                    <div className="relative px-4 py-10 bg-white mx-8 md:mx-0 shadow rounded-3xl sm:p-10">
                        <div className="max-w-md mx-auto">
                            <div className="flex items-center space-x-5">
                                <div
                                    className="h-14 w-14 bg-yellow-200 rounded-full flex flex-shrink-0 justify-center items-center text-yellow-500 text-2xl font-mono">i
                                </div>
                                <div className="block pl-2 font-semibold text-xl self-start text-gray-700">
                                    <h2 className="leading-relaxed">Modifier ce projet</h2>
                                </div>
                            </div>
                            <form className="divide-y divide-gray-200" onSubmit={handleEditProject}>
                                <div
                                    className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                    <div className="flex flex-col">
                                        <label className="leading-loose">Titre du projet</label>
                                        <input type="text"
                                               name='title'
                                               defaultValue={title}
                                               className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                                               placeholder="Titre du projet"/>
                                    </div>
                                    <div className="flex flex-col">
                                        <label className="leading-loose">Budget du client</label>
                                        <input type="number"
                                               name='budget'
                                               defaultValue={budget}
                                               className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                                               placeholder="Optionnel"/>
                                    </div>
                                    <div className="flex flex-col">
                                        <label className="leading-loose">Description</label>
                                        <textarea
                                            name='description'
                                            defaultValue={description}
                                            placeholder="Optionnel"
                                            type="text"
                                            className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600 resize-none"
                                            rows="6"></textarea>

                                        {/*<input type="text"*/}
                                        {/*       name='description'*/}
                                        {/*       className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"*/}
                                        {/*       placeholder="Optionnel"/>*/}
                                    </div>
                                </div>
                                <div className="pt-4 flex items-center space-x-4">
                                    <button
                                        onClick={() => setIsOpen(false)}
                                        type="reset"
                                        className="flex justify-center items-center w-full text-gray-900 px-4 py-3 rounded-md focus:outline-none">
                                        <svg className="w-6 h-6 mr-3" fill="none" stroke="currentColor"
                                             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                  d="M6 18L18 6M6 6l12 12"/>
                                        </svg>
                                        Annuler
                                    </button>
                                    <button
                                        type="submit"
                                        className="bg-blue-500 flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none">Créer
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default UpdateProjectModal;
