// Import FirebaseAuth and getFirebase().
import React, {useEffect, useState} from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import {navigate} from "gatsby";
import * as queryString from "query-string";

import Signedlayout, {accountTab} from "../components/Signedlayout";
import SelectProductCard from "../components/SelectProductCard";
import Product from "../components/Product";
import {getStringPriceFromCents, skeletonProducts} from "./my-products";
import {reduce} from "ramda";
import CreateProjectModal from "../components/CreateProjectModal";
import DeleteProjectModal from "../components/DeleteProjectModal";
import UpdateProjectModal from "../components/UpdateProjectModal";
import {getFirebase} from "../utils/signin";

const getLimitsByOffer = (offer) => {
    switch (offer) {
        case 'freedom':
            return {
                numberOfProjects: 5000,
                numberOfProducts: 5000,
                shoppingListEnabled: true,
                imagesQuota: 50,
            }
        case 'pro':
            return {
                numberOfProjects: 5000,
                numberOfProducts: 5000,
                shoppingListEnabled: true,
                imagesQuota: 30,
            }
        default:
            return {
                numberOfProjects: 1,
                numberOfProducts: 5,
                shoppingListEnabled: true,
                imagesQuota: 5,
            }
    }
}
export const getUserLimits = async (userId) => {
    const db = getFirebase().firestore();
    const snapshot = await db.collection("customers")
        .doc(userId)
        .collection("subscriptions")
        .where("status", "in", ["trialing", "active"])
        .get();
    if (snapshot.empty) {
        return null;
    }

    const subscription = snapshot.docs[0].data();
    const role = subscription.role;
    return getLimitsByOffer(role);
}

function Account({location}) {
    const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
    const [userId, setUserId] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [myProject, setMyProject] = useState(null);
    const [products, setProducts] = useState([]);
    const [totalProductsPrice, setTotalProductsPrice] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [showCopiedUrl, setShowCopiedUrl] = useState(false);
    const [isLoadingMutation, setIsLoadingMutation] = useState(false);
    const [isOpenDeleteProjectModal, setIsOpenDeleteProjectModal] = useState(false);
    const [userOffer, setUserOffer] = useState(null);
    const [userOfferPrice, setUserOfferPrice] = useState(null);
    const [showError, setShowError] = useState(false);
    useEffect(() => {
        const unregisterAuthObserver = getFirebase().auth().onAuthStateChanged(user => {
            setIsSignedIn(!!user);
            if (!user) return handleLogOut();

            setUserId(user.uid);
            console.log('user', user)
            setUserEmail(user.email);
            const db = getFirebase().firestore();
            db.collection("customers")
                .doc(user.uid)
                .collection("subscriptions")
                .where("status", "in", ["trialing", "active"])
                .onSnapshot(async (snapshot) => {
                    if (snapshot.empty) {
                        // Show products
                        return;
                    }
                    const subscription = snapshot.docs[0].data();
                    console.log('subscription', subscription)
                    const priceData = (await subscription.price.get()).data();
                    console.log('priceData', priceData)
                    setUserOffer(subscription.role);
                    setUserOfferPrice(priceData ? `${new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: priceData?.currency ? priceData?.currency : 'eur'
                    }).format((priceData.unit_amount / 100).toFixed(2))} par ${
                        priceData.interval
                    }`: 'N/A')
                });
            setIsLoading(false);
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);
    const handleShowError = () => {
        setShowError(true);
        setIsLoadingMutation(false);
        setTimeout(() => {
            setShowError(false);
        }, 3000);
    }
    const handleLogOut = () => {
        getFirebase().auth().signOut();
        navigate('/');
    }
    const handleOpenClientPortal = async () => {
        try {
            setIsLoadingMutation(true);
            const functionRef = firebase
                .app()
                .functions('europe-west1')
                .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
            const {data} = await functionRef({returnUrl: window.location.origin});
            window.location.assign(data.url);
            setIsLoadingMutation(false);
        } catch (e) {
            handleShowError();
        }
    }
    return (
        <Signedlayout activeTab={accountTab}>
            {isLoadingMutation && (
                <div
                    className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50 flex items-center justify-center">
                    <svg className="animate-spin ml-1 mr-3 h-28 w-28 text-blue-700"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                stroke-width="4"></circle>
                        <path className="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            )}
            <div className="pt-24 pb-24">
                <div className="container px-3 mx-auto">
                    {isLoading ? (
                        <div className=" p-4 max-w-2xl w-full mx-auto">
                            <div className="animate-pulse flex space-x-40">
                                <div className="gradient h-32 w-full rounded"></div>
                            </div>
                        </div>
                    ) : (
                        <div className="w-full mx-auto">
                            <div>
                                <p>Bonjour <b>{getFirebase().auth()?.currentUser?.displayName}</b>!</p>
                                <br/>
                                <p>Votre avez choisis l'abonnement {userOffer} et payez {userOfferPrice}.</p>

                                <h3 className='mt-6 text-base font-bold'>Voir mes factures, modifier mon abonnement ou
                                    ma méthode de paiement.</h3>
                                <button
                                    className="mt-2 bg-yellow-400 hover:bg-yellow-600 text-white font-bold py-2 px-4 border border-yellow-400 rounded"
                                    onClick={handleOpenClientPortal}
                                >
                                    Accéder à mon espace client
                                </button>
                                <div className='flex justify-end'>
                                    <button
                                        className="mt-8 bg-red-700 hover:bg-red-800 text-white font-bold py-2 px-4 border border-red-700 rounded"
                                        onClick={handleLogOut}
                                    >
                                        Se déconnecter
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {showError && (
                <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded fixed bottom-6 w-2/6 left-0 right-0 ml-auto mr-auto"
                    role="alert">
                    <strong className="font-bold">Erreur! </strong>
                    <span className="block sm:inline">Une erreur est survenue, veuillez réessayer plus tard.</span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg className="fill-current h-6 w-6 text-red-500" role="button"
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 20 20"><title>Close</title><path
                            d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                      </span>
                </div>
            )}
        </Signedlayout>
    );
}

export default Account;