import React, {useEffect, useState} from 'react';
import firebase from 'firebase';
import * as queryString from "query-string";
import {find, omit, reduce, equals, keys} from "ramda";

import SEO from "../components/seo";
import Signedlayout, {myProductsTab} from "../components/Signedlayout";
import Product from "../components/Product";
import SelectProductCard from "../components/SelectProductCard";
import {navigate} from "gatsby";
import {handleUpdateShoppingList} from "./my-project";
import {getUserLimits} from "./account";
import {getFirebase} from "../utils/signin";

export const getStringPriceFromCents = (price) => {
    if (!price) return null;

    const priceUnit = price / 100;
    return priceUnit.toFixed(2)
}

export const skeletonProducts = [0, 0, 0, 0];
const getSearchWithNewFilter = (query, additionalQuery) => {
    if (!additionalQuery) return query;

    return query.where(...additionalQuery);
}

function MyProducts({location}) {
    const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
    const [userId, setUserId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [myProducts, setMyProducts] = useState([]);
    const [myProductsFiltered, setMyProductsFiltered] = useState(null);
    const [myProductsToAddForProject, setMyProductsToAddForProject] = useState({});
    const [myProductsToRemoveForProject, setMyProductsToRemoveForProject] = useState({});
    const [myProductsSelectedForProjectInitial, setMyProductsSelectedForProjectInitial] = useState([]);
    const [myProductsUnitsForProjectInitial, setMyProductsUnitsForProjectInitial] = useState({});
    const [myProductsUnitsForProjectToUpdate, setMyProductsUnitsForProjectToUpdate] = useState({});
    const [isLoadingMutation, setIsLoadingMutation] = useState(false);
    const [showError, setShowError] = useState(false);
    const [userLimits, setUserLimits] = useState({});
    const {tag, projectId, shoppingListId, isNewProject} = queryString.parse(location.search);
    const myProductsToShow = myProductsFiltered ? myProductsFiltered : myProducts;
    const numberOfProductsToAdd = Object.keys(myProductsToAddForProject).length;
    const numberOfProductsToRemove = Object.keys(myProductsToRemoveForProject).length;
    const selectedProductsUnitsHaveChanged = !equals(myProductsUnitsForProjectToUpdate, myProductsUnitsForProjectInitial);
    // Listen to the Firebase Auth state and set the local state.
    useEffect(() => {
        const unregisterAuthObserver = getFirebase().auth().onAuthStateChanged(async user => {
            setIsSignedIn(!!user);
            setUserId(user.uid);
            const limits = await getUserLimits(user.uid);
            setUserLimits(limits);
            const db = getFirebase().firestore();
            const productsRef = db.collection('products');
            const queryBase = productsRef
                .where("authorId", "==", user.uid);
            const queryWithTag = getSearchWithNewFilter(queryBase, tag && ["tags", "array-contains", tag]);
            queryWithTag
                .limit(limits?.numberOfProducts)
                .get().then((querySnapshot) => {
                const newMyProducts = querySnapshot.docs.map((doc) => {
                    console.log(doc.id, " => ", doc.data());
                    const product = doc.data();
                    const {
                        title,
                        reference,
                        price,
                        url,
                        images,
                        tags,
                        projectsId,
                        unitsByProjectId,
                        dimension,
                        myDescription
                    } = product;
                    const isSelected = projectId && projectsId && find((id) => id === projectId, projectsId);
                    return {
                        id: doc.id,
                        title,
                        price: getStringPriceFromCents(price),
                        image: images && images[0],
                        tags,
                        isSelected,
                        units: isSelected && unitsByProjectId ? unitsByProjectId[projectsId] : 1,
                    }
                });
                setMyProducts(newMyProducts);
                const selectedProducts = reduce((acc, {isSelected, id}) => {
                    if (isSelected) return [...acc, id];

                    return acc;
                }, [], newMyProducts);
                setMyProductsSelectedForProjectInitial(selectedProducts);
                const selectedUnitsProducts = reduce((acc, {isSelected, units = 1, id}) => {
                    if (isSelected) return {...acc, [id]: units};

                    return acc;
                }, {}, newMyProducts);
                setMyProductsUnitsForProjectInitial(selectedUnitsProducts);
                setMyProductsUnitsForProjectToUpdate(selectedUnitsProducts);
                setIsLoading(false);
            }).catch(() => {
                handleShowError();
            });
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);
    const handleShowError = () => {
        setShowError(true);
        setIsLoadingMutation(false);
        setTimeout(() => {
            setShowError(false);
        }, 3000);
    }
    const handleSelectedProduct = (id, isSelected) => {
        const isInsideInitialSelectedProducts = find((pId) => pId === id, myProductsSelectedForProjectInitial);
        const newMyProductsToAddForProject = isSelected && !isInsideInitialSelectedProducts ?
            {
                ...myProductsToAddForProject,
                [id]: true
            } : omit([id], myProductsToAddForProject);
        setMyProductsToAddForProject(newMyProductsToAddForProject);
        const newMyProductsToRemoveForProject = !isSelected && isInsideInitialSelectedProducts ?
            {
                ...myProductsToRemoveForProject,
                [id]: true
            } : omit([id], myProductsToRemoveForProject);
        setMyProductsToRemoveForProject(newMyProductsToRemoveForProject);
    }
    const handleSelectedUnitsProduct = (id, units) => {
        setMyProductsUnitsForProjectToUpdate({
                ...myProductsUnitsForProjectToUpdate,
                [id]: units
            });
    }
    const handleSaveProductsToProject = async () => {
        try {
            if (!isSignedIn || !projectId) return 'SHOW ERROR';

            setIsLoadingMutation(true);
            const productsIdToAdd = Object.keys(myProductsToAddForProject);
            const productsIdToRemove = Object.keys(myProductsToRemoveForProject);
            const firestoreDb = getFirebase().firestore();
            const collectionRef = firestoreDb.collection("products");
            await Promise.all(productsIdToAdd.map(async (productId) => {
                await collectionRef.doc(productId).set({
                    projectsId: [projectId],
                }, {merge: true});
            }));
            await Promise.all(keys(myProductsUnitsForProjectToUpdate).map(async (productId) => {
                await collectionRef.doc(productId).set({
                    unitsByProjectId: {
                        [projectId]: myProductsUnitsForProjectToUpdate[productId],
                    }
                }, {merge: true});
            }));
            await Promise.all(productsIdToRemove.map(async (productId) => {
                await collectionRef.doc(productId).set({
                    projectsId: getFirebase().firestore.FieldValue.arrayRemove(projectId)
                }, {merge: true});
            }));
            await handleUpdateShoppingList(projectId, shoppingListId, userId);
            setIsLoadingMutation(false);
            handleGoToProject();
        } catch (e) {
            console.log('handleSaveProductsToProject error', e)
            handleShowError();
        }
    }
    const handleBackToProject = () => {
        window.history.go(-1);
    };
    const handleGoToProject = () => {
        if (!projectId) return;

        navigate(`/my-project?projectId=${projectId}`);
    };
    const handleRemoveQuery = (queryToRemove) => {
        const queryToRemoveRegex = new RegExp(`[\?&]${queryToRemove}=[^&]+`, "i");
        history.replaceState && history.replaceState(
            null, '', location.pathname + location.search.replace(queryToRemoveRegex, '').replace(/^&/, '?')
        );
        window.location.reload();
    }
    const handleGetMyProductSearched = (event) => {
        const text = event.target.value;
        if (!text) {
            setMyProductsFiltered(null);
            return;
        }

        const regex = RegExp(text.toLowerCase(), 'g');
        setMyProductsFiltered(
            myProducts.filter(item =>
                item.title.toLowerCase().startsWith(text.toLowerCase()) ||
                item.title.toLowerCase().search(regex) >= 0 ||
                item.tags?.find(tag => tag.toLowerCase().startsWith(text.toLowerCase())),
            ),
        )
    };

    return (
        <Signedlayout activeTab={myProductsTab}>
            <SEO
                title="DeerHome - Mes produits"
                keywords={[`décoration`, `meubles`, `inventaire`, `liste shopping`, 'mes produits', 'mes meubles']}
            />
            {isLoadingMutation && (
                <div
                    className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50 flex items-center justify-center">
                    <svg className="animate-spin ml-1 mr-3 h-28 w-28 text-blue-700"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                stroke-width="4"></circle>
                        <path className="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            )}
            <div className="pt-24">
                <main className="my-8">
                    <div className="container mx-auto px-6 relative">
                        <div className="relative my-6 max-w-lg mx-auto">
                                <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
                                    <svg className="h-5 w-5 text-gray-500" viewBox="0 0 24 24" fill="none">
                                        <path
                                            d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                    </svg>
                                </span>

                            <input
                                className="w-full border rounded-md pl-10 pr-4 py-2 focus:border-blue-500 focus:outline-none focus:shadow-outline"
                                type="text"
                                placeholder="Rechercher"
                                onChange={handleGetMyProductSearched}
                            />
                        </div>
                        <h3 className="text-gray-700 text-2xl font-medium">{projectId ? 'Modifier les produits de mon projet' : 'Mes produits'}</h3>
                        <div className='flex'>
                            <span className="mt-3 text-sm text-gray-500">{myProductsToShow.length} Produits</span>
                            {tag && (
                                <button className="ml-1 mt-3 text-sm text-gray-500" onClick={() => {
                                    handleRemoveQuery('tag')
                                }}>
                                    <span className="text-sm text-gray-500">•</span>
                                    <span className="ml-1 text-sm text-gray-500 hover:underline">{tag}</span>
                                    <span className="ml-1 text-sm text-white rounded bg-gray-500 pl-1 pr-1">x</span>
                                </button>)}
                        </div>
                        {projectId && (
                            <button
                                className={"my-6 bg-gray-400 hover:bg-gray-500 text-white py-2 px-4 rounded"}
                                onClick={handleBackToProject}
                            >
                                Retour au projet
                            </button>
                        )}
                        {isNewProject && (
                            <div className="bg-blue-100 border-t border-b border-blue-300 px-4 py-3"
                                 role="alert"
                                 style={{
                                     color: '#6fb8ff',
                                     background: '#6fb8ff17'
                                 }}
                            >
                                <p className="font-bold">Cliquez sur les produits que vous souhaitez ajouter à votre projet.</p>
                            </div>
                        )}
                        {myProducts.length <= 0 && !isLoading && (
                            <div className="bg-blue-100 rounded-xl my-10 px-4 py-3"
                                 style={{
                                     color: '#6fb8ff',
                                     background: '#6fb8ff17'
                                 }}
                            >
                                <p className="font-bold">Pour ajouter des produits:</p>
                                <ul className='ml-10 mt-4 list-decimal'>
                                    <li className="my-1">Installer notre <a className='font-bold' href='https://chrome.google.com/webstore/detail/deerhome-plugin/lnggcnkmaobmdepcnmeldonnmhopbdmp' target='_blank'>extension/plugin chrome DeerHome: ici</a>.</li>
                                    <li className="my-1">Ouvrez l'extension et connectez-vous y avec votre compte lié à DeerHome.</li>
                                    <li className="my-1">Dirigez vous sur le site marchand du produit que vous souhaitez enregistrer.</li>
                                    <li className="my-1">Ouvrez l'extension et activez le plugin.</li>
                                    <li className="my-1">Le site s'est rechargé avec désormais un bouton associé du logo DeerHome sur les images de votre produit.</li>
                                    <li className="my-1">Appuyez sur ce bouton pour ouvrir une popup vous permettant d'entrer les informations de votre produit et de l'enregistrer.</li>
                                </ul>
                            </div>
                        )}

                        <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6 pb-6">
                            {isLoading && (
                                skeletonProducts.map(() => (
                                    <div className=" p-4 max-w-2xl w-full mx-auto">
                                        <div className="animate-pulse flex space-x-40">
                                            <div className="gradient h-72 w-full"></div>
                                        </div>
                                    </div>
                                ))
                            )}
                            {myProductsToShow.map(({title, price, image, id, isSelected, units = 1}) => projectId ? (
                                <SelectProductCard
                                    handleSelectedProduct={handleSelectedProduct}
                                    handleSelectedUnitsProduct={handleSelectedUnitsProduct}
                                    isSelectedInit={isSelected}
                                    title={title}
                                    price={price}
                                    image={image}
                                    id={id}
                                    unitsInit={units}
                                />
                            ) : (
                                <Product title={title} price={price} image={image} id={id}/>
                            ))}

                        </div>
                        {projectId && (numberOfProductsToAdd > 0 || numberOfProductsToRemove > 0 || selectedProductsUnitsHaveChanged) && (
                            <div className="sticky bottom-6 flex justify-center">
                                <button
                                    className={
                                        // "bg-gray-400 text-white font-bold py-2 px-4 rounded " :
                                        "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded"
                                    }
                                    onClick={handleSaveProductsToProject}
                                >
                                    {((numberOfProductsToAdd > 0 && numberOfProductsToRemove > 0) || selectedProductsUnitsHaveChanged) && (
                                        <span>
                                        Enregistrer
                                    </span>
                                    )}
                                    {!selectedProductsUnitsHaveChanged && numberOfProductsToAdd > 0 && numberOfProductsToRemove <= 0 && (
                                        <span>

                                        Ajouter les ({numberOfProductsToAdd}) produits au projet
                                    </span>
                                    )}
                                    {!selectedProductsUnitsHaveChanged && numberOfProductsToAdd <= 0 && numberOfProductsToRemove > 0 && (
                                        <span>

                                        Supprimer les ({numberOfProductsToRemove}) produits du projet
                                    </span>
                                    )}
                                </button>

                            </div>
                        )}
                    </div>
                </main>
            </div>
            {showError && (
                <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded fixed bottom-6 w-2/6 left-0 right-0 ml-auto mr-auto"
                    role="alert">
                    <strong className="font-bold">Erreur! </strong>
                    <span className="block sm:inline">Une erreur est survenue, veuillez réessayer plus tard.</span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg className="fill-current h-6 w-6 text-red-500" role="button"
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 20 20"><title>Close</title><path
                            d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                      </span>
                </div>
            )}
        </Signedlayout>
    );
}

export default MyProducts;
