import {Link} from "gatsby";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {navigate} from "gatsby-link";

function SelectProductCard({
                               title,
                               price,
                               image,
                               id,
                               unitsInit = 1,
                               isSelectedInit = false,
                               handleSelectedProduct,
                               handleSelectedUnitsProduct
                           }) {
    const [isSelected, setIsSelected] = useState(isSelectedInit);
    const [units, setUnits] = useState(unitsInit);
    const handleSelectProduct = () => {
        const newIsSelected = !isSelected;
        setIsSelected(newIsSelected);
        handleSelectedProduct(id, newIsSelected, units);
    }
    const handleDecUnits = () => {
        const newUnits = units <= 0 ? units : units - 1;
        if (newUnits <= 0) {
            setIsSelected(false);
            handleSelectedProduct(id, false, units);
        }

        setUnits(newUnits);
        handleSelectedUnitsProduct(id, newUnits);
    }
    const handleIncUnits = () => {
        const newUnits = units > 5000 ? units : units + 1;
        setUnits(newUnits);
        handleSelectedUnitsProduct(id, newUnits);
    }
    const containerSelectedClass = isSelected ? ' border-solid border-4 border-blue-500' : '';

    return (
        <div className={`w-full max-w-sm mx-auto rounded-md shadow-md overflow-hidden`}>
            <div
                onClick={handleSelectProduct}
                className={`cursor-pointer ${containerSelectedClass}`}>
                <div className="flex items-end justify-end h-56 w-full bg-cover"
                     style={{backgroundImage: `url("${image}")`}}>
                </div>
                <div className="px-5 py-3 inline-flex items-center">
                    <div>
                        <h3 className="text-gray-700 uppercase" style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical"
                        }}>{title}</h3>
                        <span className="text-gray-500 mt-2">{price} €</span>
                    </div>
                    <div>
                        <input type="radio" className="form-radio h-6 w-6 cursor-pointer" checked={isSelected}/>
                    </div>
                </div>
            </div>
            {isSelected && (
                <div className='flex items-center justify-center px-10 py-5'>
                    <div className='flex-1 flex items-center justify-center'>
                        <button onClick={handleDecUnits}>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 className="h-10 w-10 text-blue-500 transform hover:scale-110" viewBox="0 0 20 20"
                                 fill="currentColor">
                                <path fill-rule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
                                      clip-rule="evenodd"/>
                            </svg>
                        </button>
                    </div>
                    <div className='flex-1 flex items-center justify-center text-blue-500 text-xl'>
                        x{units}
                    </div>
                    <div className='flex-1 flex items-center justify-center'>
                        <button onClick={handleIncUnits}>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 className="h-10 w-10 text-blue-500 transform hover:scale-110" viewBox="0 0 20 20"
                                 fill="currentColor">
                                <path fill-rule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                                      clip-rule="evenodd"/>
                            </svg>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

SelectProductCard.propTypes = {
    title: PropTypes.string,
    price: PropTypes.string
};

SelectProductCard.defaultProps = {
    title: '',
    price: ''
};

export default SelectProductCard;
