import {Link} from "gatsby";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {navigate} from "gatsby-link";

function Product({title, price, image, id, handleClickProduct, units}) {
    const [isExpanded, toggleExpansion] = useState(false);
    const handleRedirectToProduct = () => {
        if (handleClickProduct) return handleClickProduct();

        navigate(`/my-product?productId=${id}`);
    }

    return (
        <div onClick={handleRedirectToProduct}
             className="w-full max-w-sm mx-auto rounded-md shadow-md relative overflow-hidden transform hover:scale-105 cursor-pointer">
            {units && (
                <div className='text-white bg-blue-300 absolute p-3 right-0 top-0'>x{units}</div>
            )}
            <div className="flex items-end justify-end h-56 w-full bg-cover"
                 style={{backgroundImage: `url("${image}")`}}>
            </div>
            <div className="px-5 py-3">
                <h3 className="text-gray-700 uppercase" style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical"
                }}>{title}</h3>
                {units ? (
                    <div className={'flex-col'}>
                        <div className="text-gray-500 mt-2">{price} € / unité</div>
                        <div className="text-gray-500 mt-2">Total: {(price * units).toFixed(2)} €</div>
                    </div>
                ) : (
                    <span className="text-gray-500 mt-2">{price} €</span>
                )}
            </div>
        </div>
    );
}

Product.propTypes = {
    title: PropTypes.string,
    price: PropTypes.string
};

Product.defaultProps = {
    title: '',
    price: ''
};

export default Product;
